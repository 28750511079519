import React from "react";
import SEO from "../../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SideVerticalText from "../../components/layout/sideVerticalText";
import BottomFactory from "../../components/layout/BottomFactory";
import { TiArrowSortedDown } from "react-icons/ti";
import scrollTo from "gatsby-plugin-smoothscroll";
import FadeIn from "../../components/animations/FadeIn";

const WeighingDevices = ({ intl }) => {
	const data = useStaticQuery(graphql`{
  weighingDevicesGraphic: file(
    relativePath: {eq: "machines/weighingDevices/weighingDevicesGraphic.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 700, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  allWeighingDevicesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        title
        goTo
      }
    }
  }
}
`);
	return <>
        <SEO title={intl.formatMessage({ id: "machineSelection.weighing" })}/>
        <div className="allMachinesFirstLevelPage">
            <div className="globalMachineSectionHome">
                <div className="globalBoxMachineSideText">
                    <FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
                </div>

                <div className="globalBoxMachineLeft">
                <div className="weighingTitleCircle"></div>
                    <p className="allMachinesFirstLevelTitle ">
                        {intl.formatMessage({ id: "machineSelection.weighing" })}
                    </p>
                    <p className="allMachinesFirstLevelText">
                        {intl.formatMessage({ id: "weighingDevices.about" })}
                    </p>
                    <div className="allMachinesFirstLevelLearnSection">
                        <svg width="1" viewBox="0 0 2 135" fill="none" alt="tehnomatikSvg">
                            <line
                                x1="0.499986"
                                y1="0.996269"
                                x2="1.49999"
                                y2="134.996"
                                stroke="#3A3C40"
                            />
                        </svg>
                        <button
                            className="learnMoreButton"
                            onClick={() => scrollTo("#allMachinesFirstLevelCards")}
                        >
                            <TiArrowSortedDown size={28} />
                        </button>
                    </div>
                </div>

                <div className="globalBoxMachineRight">
                    <GatsbyImage
                        image={data.weighingDevicesGraphic.childImageSharp.gatsbyImageData}
                        className="allMachinesFirstLevelGraphic"
                        alt="weighingDevicesGraphic"
                        loading="eager" />
                </div>

                <div className="globalBoxMachineEmptyRight"></div>

                <div className="globalBoxMachineBottomText">
                    <BottomFactory />
                </div>
            </div>

            <div className="allMachinesFirstLevelCardsSection" id="allMachinesFirstLevelCards">
                <div className="allMachinesFirstLevelCardsTitle">
                    <svg
                        className="allMachinesFirstLevelSvg"
                        viewBox="0 0 791 17"
                        fill="none"
                        alt="tehnomatikSvg"
                    >
                        <path
                            d="M791 17H0C0 17 224.117 2.58442e-06 401.474 0C578.831 -2.58441e-06 791 17 791 17Z"
                            fill="#3A3C40"
                        />
                    </svg>
                    <p>
                        {intl.formatMessage({
                            id: "weighingDevices.selector",
                        })}
                    </p>
                    <svg
                        className="allMachinesFirstLevelSvg"
                        viewBox="0 0 791 1"
                        fill="none"
                        alt="tehnomatikSvg"
                    >
                        <line
                            x1="-4.6128e-08"
                            y1="0.5"
                            x2="791"
                            y2="0.499934"
                            stroke="#3A3C40"
                        />
                    </svg>
                </div>
                <div className="allMachinesFirstLevelCardsWrapper">
                    {data.allWeighingDevicesDataJson.edges.map((item, index) => {
                        return (
                            <Link to={item.node.goTo} key={index}>
                                <div className="allMachinesCards">
                                    <div className="machineFirstLevelCardImageBox">
                                        <GatsbyImage
                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                            className="machinesFirstLevelCardImage"
                                            alt="weighingDevicesImages"
                                            loading="eager" />
                                    </div>
                                    <div className="allMachinesFirstLevelItemTextDiv">
                                        <div className="allMachinesFirstLevelItemText">
                                            <p>
                                                <FormattedMessage id={item.node.title} />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="weighingDevicesCardBottomLine"></div>
                                </div>
                            </Link>
                        );
                    })}
                    <div className="goBackBox">
                        <Link to="/machines#machineSelector">
                            <button className="goBack">
                                {intl.formatMessage({
                                    id: "machineSelection.goBackcardSelection",
                                })}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default injectIntl(WeighingDevices);
