import React from "react";
import { injectIntl } from "gatsby-plugin-react-intl";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const BottomComponent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	@media screen and (max-width: 992px) {
		align-items: center;
		padding:0 5%;
	}
`;

const Text = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 40px;
	width: calc(100% - 100px);
	border-bottom: 2px solid #799498;
	font-family: 'Oxanium', cursive;
	font-weight: 400;
	font-size: 11px;
	line-height: 20px;
	letter-spacing: 2.5px;
	text-transform: uppercase;
	color: #3a3c40;
	@media screen and (max-width: 420px) {
		font-size: 9px;
		letter-spacing: 1.5px;
		height: 30px;
	}
	@media screen and (max-width: 390px) {
		width: 100%;
		font-size: 9px;
		letter-spacing: 1px;
	}
	@media screen and (max-width: 240px) {
		font-size: 4vw;
	}
`;

const Image = styled.div`
	background: #799498;
	height: 40px;
	width: 100px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 420px) {
		height: 30px;
		width: 80px;
	}
	@media screen and (max-width: 390px) {
		display: none;
	}
`;

const BottomFactory = ({ intl }) => {
	const data = useStaticQuery(graphql`{
  sectionPic: file(relativePath: {eq: "homePage/sectionPic.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 70, layout: FIXED)
    }
  }
}
`);
	return (
        <BottomComponent>
			<Text>
				<p>{intl.formatMessage({ id: "about.subTitle" })}</p>
			</Text>
			<Image>
				<GatsbyImage
                    image={data.sectionPic.childImageSharp.gatsbyImageData}
                    className="sectionPic"
                    alt="sectionPic"
                    loading="eager" />
			</Image>
		</BottomComponent>
    );
};

export default injectIntl(BottomFactory);
