import styled, { keyframes } from 'styled-components';
import AnimationRoot from './AnimationRoot';

const FadeInAnimation = keyframes`  
  from { opacity: 0;}
  to { opacity: 1;}
`; 
const FadeIn = styled(AnimationRoot)`
  animation-name: ${FadeInAnimation};
`; 
export default FadeIn;