import React from "react";
import { injectIntl } from "gatsby-plugin-react-intl";
import styled from "styled-components";

const SideComponent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: calc(90vh - (10vh - 40px));
	@media screen and (max-width: 992px) {
		display: none;
	}
	@media screen and (min-width: 1920px) {
		height: 1000px;
	}
`;
const VerticalLine = styled.span`
	height: 27vh;
	width: 5px;
	display: flex;
	justify-content: space-evenly;
`;

const DotDiv = styled.div`
	height: 4vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;
const DotWhite = styled.span`
	background: white;
	width: 7px;
	height: 7px;
	border: 1px solid rgba(0, 0, 0, 0.75);
	border-radius: 50%;
`;
const DotBlack = styled.span`
	background: #44464a;
	display: flex;
	width: 7px;
	height: 7px;
	margin: 5% 0;
	border: 1px solid #44464a;
	border-radius: 50%;
`;
const VerticalText = styled.p`
	height: auto;
	padding: 0;
	font-family: 'Oxanium', cursive;
	font-size: 12px;
	letter-spacing: 4px;
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
`;
const EmptyDiv = styled.div`
	height: calc(10vh - 40px);
`;

const SideVerticalText = ({ intl }) => {
	return (
		<>
			<SideComponent>
				<VerticalLine>
					<svg width="1" viewBox="0 0 1 335" fill="none" alt="tehnomatikSvg">
						<line
							x1="0.5"
							y1="-2.18557e-08"
							x2="0.500015"
							y2="335"
							stroke="#3A3C40"
						/>
					</svg>
				</VerticalLine>
				<VerticalText>
					{intl.formatMessage({ id: "index.engineering" })}
				</VerticalText>
				<VerticalLine>
					<svg width="1" viewBox="0 0 1 335" fill="none" alt="tehnomatikSvg">
						<line
							x1="0.5"
							y1="-2.18557e-08"
							x2="0.500015"
							y2="335"
							stroke="#3A3C40"
						/>
					</svg>
				</VerticalLine>
				<DotDiv>
					<DotWhite />
					<DotWhite />
					<DotBlack />
				</DotDiv>
			</SideComponent>
			<EmptyDiv />
		</>
	);
};

export default injectIntl(SideVerticalText);
